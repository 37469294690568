<template>
  <div class="container-aux-for-center-tabs">
    <div v-if="Object.keys(allEvents).length !== 0 && !showEventInTabs && $route.path !== '/checkout' &&  $route.path !== '/branch'" class="event-v2-container__tab" @click="$emit('actionModalEvents', true)">
      <div class="event-v2-container__tab__div">
        <div>★&nbsp; {{$t('eventsTabOpen')}} </div>
      </div>
    </div>
    <div
      @click="goToMenu()"
      id="menu-button"
      v-if="params.isActiveMenuDigital == 2 && $route.path !== '/checkout'"
    >
      <div class="button-title">
        <span>{{$t('menu')}}</span>
      </div>
    </div>
    <div id="giftCard-button" v-if="params.activateGiftCard && $route.path !== '/checkout'" :style="`${giftCardSection ? 'width: 220px': ''}`">
      <div class="button-title" @click="openGiftCardSection">
        <span>{{$t('giftCard')}}</span>
      </div>
      <div class="button-cont" :style="`${!giftCardSection ? 'display: none;':''}`">
        <div class="icon-gift">
          <i class="iconGift"></i>
        </div>
        <div class="text-gift">
          <span>{{ (parseInt(vendor.id) === 185) ? $t('sendGiftSpecialBiferia') : $t('sendGiftSpecial') }}.</span>
        </div>
        <div class="button-gift">
          <a @click="goToGiftCard()">¡{{ $t('givePresent') }}!</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['prevDate', 'value', 'editZone', 'showEventInTabs'],
  data () {
    return {
      giftCardSection: false
    };
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    openGiftCardSection () {
      this.giftCardSection = !this.giftCardSection;
    },
    goToMenu () {
      if (this.params.externalUrlMenu !== null && this.params.externalUrlMenu !== '') {
        window.open(this.params.externalUrlMenu, '_blank');
        return;
      }
      window.open(`https://qr.precompro.com/?vendor=${this.vendor.url}`, '_blank');
    },
    goToGiftCard () {
      window.location.href = '/giftCard';
    }
  }
};
</script>
<style lang="scss">
  .container-aux-for-center-tabs{
    z-index: 8;
    min-width: 33px;
    height: auto;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    display: grid;
    gap: 35px;
    justify-items: right;
  }
  #giftCard-button {
    display: flex;
    right: -187px;
    box-shadow: -10px 8px 7px #00000029;
    background: var(--cardBackgroundColorAditional);
    width: 33px;
    height: 133px;
    border-radius: 13px 0px 0px 13px;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    margin-right: -1px;
    &:hover {
      right: 0px;
    }
    .button-title {
      cursor: pointer;
      border-radius: 13px 0px 0px 13px;
      flex: 1;
      border-right: solid 1px var(--cardTitleColorAditional);
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        color: var(--cardTitleColorAditional);
        writing-mode: vertical-lr;
        transform: rotate(180deg);
        font-size: 16px;
        font-weight: bold;
        text-align: center;
      }
    }
    .button-cont {
      flex: 5;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 5px 10px;
      .icon-gift {
        margin-top: 8px;
        .iconGift {
          display: flex;
          margin-right: 5px;
          -webkit-mask-repeat: no-repeat !important;
          mask-repeat: no-repeat !important;
          mask-position: center !important;
          height: 35px;
          width: 35px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: -3px;
          -webkit-mask-size: contain;
          mask-size: contain;
          background: var(--cardTitleColorAditional);
          -webkit-mask-image: url(../../assets/images/icons/icon_gift.svg);
          mask-image: url(../../assets/images/icons/icon_gift.svg);
        }
      }
      .text-gift {
        display: flex;
        margin-top: 8px;
        span {
          color: var(--cardTitleColorAditional);
          font-size: 13px;
          text-align: center;
          padding: 3%;
        }
      }
      .button-gift {
        display: flex;
        margin: 3px;
        a {
          height: 27px;
          width: 80px;
          border-radius: 10px;
          text-align: center;
          font-weight: bold;
          color: var(--cardTitleColorAditional) !important;
          border: 1px solid var(--cardTitleColorAditional) !important;
        }
      }
    }
  }
  #menu-button {
    display: flex;
    right: -187px;
    box-shadow: -10px 8px 7px #00000029;
    background: var(--cardBackgroundColorAditional);
    width: 33px;
    height: 133px;
    margin-right: -1px;
    border-radius: 13px 0px 0px 13px;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    .button-title {
      cursor: pointer;
      border-radius: 13px 0px 0px 13px;
      flex: 1;
      border-right: solid 1px var(--cardTitleColorAditional);
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        color: var(--cardTitleColorAditional);
        writing-mode: vertical-lr;
        transform: rotate(180deg);
        font-size: 16px;
        font-weight: bold;
        text-align: center;
      }
    }
    .button-cont {
      flex: 5;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 5px 10px;
      .icon-gift {
        margin-top: 8px;
        .iconGift {
          display: flex;
          margin-right: 5px;
          -webkit-mask-repeat: no-repeat !important;
          mask-repeat: no-repeat !important;
          mask-position: center !important;
          height: 35px;
          width: 35px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: -3px;
          -webkit-mask-size: contain;
          mask-size: contain;
          background: var(--cardTitleColorAditional);
          -webkit-mask-image: url(../../assets/images/icons/icon_gift.svg);
          mask-image: url(../../assets/images/icons/icon_gift.svg);
        }
      }
      .text-gift {
        display: flex;
        margin-top: 8px;
        span {
          color: var(--cardTitleColorAditional);
          font-size: 13px;
          text-align: center;
          padding: 3%;
        }
      }
      .button-gift {
        display: flex;
        margin: 3px;
        a {
          height: 27px;
          width: 80px;
          border-radius: 10px;
          text-align: center;
          font-weight: bold;
          color: var(--cardTitleColorAditional) !important;
          border: 1px solid var(--cardTitleColorAditional) !important;
        }
      }
    }
  }
</style>
